<script setup lang="ts">
import { SettingsIcon } from 'vue-tabler-icons';
import { TranscriptionLang } from './components';
import { ROUTE_NAMES } from '@/constants/routeNames';
import { MenuLink } from '@/api/types';
import SidebarLink from '@/components/SidebarMenu/components/SidebarLink.vue';
import { useSidebarSize } from '@/components/SidebarMenu/hooks';

const links: MenuLink[] = [
  {
    title: 'Settings',
    icon: SettingsIcon,
    iconSize: 20,
    to: { name: ROUTE_NAMES.SETTINGS_NEW.INDEX },
  },
];

const { isDesktop } = useSidebarSize();
</script>

<template>
  <div class="flex flex-col gap-y-[10px]">
    <TranscriptionLang />
    <SidebarLink
      v-for="{ title, icon, to, iconSize } in links"
      :key="title"
      :title="title"
      :to="to"
      :icon="icon"
      :icon-size="iconSize"
    />
    <UserInfo
      v-if="!isDesktop"
      :avatar-size="24"
      name-class="typo-body1"
      class="p-2"
    />
  </div>
</template>
