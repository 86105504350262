<script setup lang="ts">
import { SubscriptionInterval } from '@/api/types';
import { useBillingPeriodSelector, BillingPeriodLabelsMap } from './hooks';
import { useDisplay } from 'vuetify';
import { computed } from 'vue';

const { billingPeriodSelector } = useBillingPeriodSelector();
const { smAndUp } = useDisplay();

const labelsMap = computed(() => {
  if (smAndUp.value) {
    return BillingPeriodLabelsMap;
  } else {
    return {
      ...BillingPeriodLabelsMap,
      [SubscriptionInterval.Year]: 'Annually',
    };
  }
});
</script>
<template>
  <div class="flex items-center p-[2px] rounded bg-grey-10 lg:w-fit">
    <label
      v-for="interval in SubscriptionInterval"
      :key="interval"
      class="relative max-lg:w-full max-lg:text-center py-[6px] pl-3 pr-4 cursor-pointer rounded"
      :class="{
        'bg-primary': billingPeriodSelector === interval,
      }"
    >
      <input
        v-model="billingPeriodSelector"
        name="billing-period"
        :value="interval"
        type="radio"
        class="invisible z-0 absolute"
      />
      <span class="typo-body1 font-medium">
        {{ labelsMap[interval] }}
      </span>
    </label>
  </div>
</template>
