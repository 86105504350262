import { LocationAsRelativeRaw } from 'vue-router';
import { TablerIconComponent } from 'vue-tabler-icons';
import { InputTypes, OutputTypes } from '@/api';
import type { ArrayElement, Modify } from '@/utils/types';
import type {
  Account,
  Person,
  Participant,
  ConversationLanguage,
} from '@noty/database';

export { Person, ConversationLanguage };
export type AvatarProps = Pick<Person, 'name' | 'email' | 'avatarUrl'>;

export type User = OutputTypes['auth']['signIn'];
export type CustomUser = Modify<
  User,
  {
    onboarding: OnboardingType | null;
    onboardingStatus: OnboardingStatus;
  }
>;
export type UserUpdateData = InputTypes['users']['updatePrincipalData'];
export type UserGetOAuth2Token =
  InputTypes['users']['credentials']['handleOAuthCode'];
export type WorkspacePerson = ArrayElement<
  OutputTypes['workspaces']['getPeople']
>;
export type ReturnedWorkspaces = OutputTypes['workspaces']['getByUserId'];
export type Workspace = ArrayElement<ReturnedWorkspaces>;
export type ReturnedSortedWorkspaces =
  OutputTypes['workspaces']['getByUserIdSorted'];
export type SortedWorkspacesAccounts =
  ReturnedSortedWorkspaces[number]['accounts'];
export type ConversationsByQuery = OutputTypes['conversations']['getByQuery'];
export type ReturnedConversation = ConversationsByQuery['data'][number] & {
  canEdit?: OutputTypes['conversations']['canEdit']['canEdit'];
};
export type ReturnedSearchedConversation =
  | OutputTypes['conversations']['getByQuery']
  | OutputTypes['conversations']['getBySearchQuery']
  | undefined;
export type ConversationWithPersmissions =
  OutputTypes['conversations']['getPresentationById'] &
    OutputTypes['conversations']['canEdit'];
export type ParticipantPerson = Participant & { person: Person };
export type ConversationParticipantPerson = ReturnedConversation & {
  participants: ParticipantPerson[];
};
export type ConversationInputQuery =
  InputTypes['conversations']['getByQuery'] & {
    search?: InputTypes['conversations']['getBySearchQuery']['search'];
  };
export type ConversationMembersInputData = Omit<
  InputTypes['conversations']['createConversationAccess'],
  'conversationId'
>;
export type WorkspaceAccountPerson = OutputTypes['workspaces']['getById'];
export type Folder = ArrayElement<
  OutputTypes['workspaces']['getById']['spaces']
>;
export type FolderWithConversations = ArrayElement<
  OutputTypes['spaces']['getPrincipalSpaces']
>;
export type WorkspacePricing = OutputTypes['workspaces']['getById']['pricing'];
export type Plans =
  OutputTypes['subscriptions']['getWorkspaceSubscription']['plan'];
export type WorkspaceAllowedToJoin =
  OutputTypes['workspaces']['getWorkspaceAllowedToJoin'];
export type WorkspaceUpdateInput = InputTypes['workspaces']['update'];
export type ReturnedBlock = ArrayElement<
  OutputTypes['blocks']['getByConversation']
> & {
  highlight?: ArrayElement<OutputTypes['blocks']['getByConversation']>;
};
export type BlockCreateData = Omit<
  InputTypes['blocks']['createBlock'],
  'conversationId'
>;
export type BlockUpdateData = Omit<
  InputTypes['blocks']['updateBlock']['blockData'],
  'blockId'
>;

export type SpaceUpdateData = InputTypes['spaces']['updateSpace']['spaceData'];

export type CreateSpaceDto = InputTypes['spaces']['createSpace'];
export type UpdateSpaceDto = InputTypes['spaces']['updateSpace'];
export type DeleteSpaceDto = InputTypes['spaces']['deleteSpace'];
export type InviteToSpaceDto = InputTypes['spaces']['inviteSpaceMember'];
export type UpdateSpaceAccessRoleDto =
  InputTypes['spaces']['updateSpaceAccessRole'];
export type RemoveFromSpaceDto = InputTypes['spaces']['deleteSpaceAccess'];

export type ConversationUpdateInput = InputTypes['conversations']['update'];
export type ConversationGetBySearchQueryInput =
  InputTypes['conversations']['getBySearchQuery'];
export type SpaceAccountPerson = OutputTypes['spaces']['updateSpace'];
export type AccountPerson = Account & { person: Person };
export type AssistsType = ArrayElement<
  OutputTypes['assists']['getForConversation']
>;
export type CreateAssistDto = InputTypes['assists']['create'];
export type AssistBlocks = ArrayElement<
  OutputTypes['blocks']['getAssistBlocks']
>;
export type ToDoItem = ArrayElement<
  OutputTypes['todoItems']['forConversation']
>;
export type UpdateTodoItemDto = InputTypes['todoItems']['update'];
export type GoogleDocument = ArrayElement<
  OutputTypes['conversationDocuments']['list']
>;
export type GoogleCalendar = ArrayElement<
  OutputTypes['workspaces']['getCalendars']
>;

export type AIMessageAskQuestionDto = InputTypes['aiMessages']['askQuestion'];

export type Products = OutputTypes['subscriptions']['getProducts'];
export type Product = ArrayElement<Products>;
export type CurrentSubscription =
  OutputTypes['subscriptions']['getWorkspaceSubscription'];

export type OAuth2Client = OutputTypes['oauth2']['getOAuthClient'];

export type UpdateTranscriptLinesInput =
  InputTypes['transcript']['updateLinesText'];
export type TranscriptLine = ArrayElement<
  OutputTypes['transcript']['getTranscript']
>;

export enum ZoomJoinToType {
  ALL = 'ALL',
  OWNED = 'OWNED',
  INTERNAL = 'INTERNAL',
  EXTERNAL = 'EXTERNAL',
  DISABLED = 'DISABLED',
}

export interface InvitedPersonType {
  email: string;
  role: AccountRole | SpaceRole;
}

export interface MemberType {
  id: string;
  userId?: string | null;
  role: AccountRole | SpaceRole;
  person: Person | InvitedPersonType;
  zoomBotJoiningStrategy: ZoomJoinToType;
}

export interface ConfirmPopupOptionsType {
  title?: string;
  primaryButtonText?: string;
  secondaryButtonText?: string;
  confirmButtonAppearance?: string;
}

export enum OnboardingStatus {
  Usage = 'USAGE',
  Goal = 'GOAL',
  Workspace = 'WORKSPACE',
  Card = 'CARD',
  Extension = 'EXTENSION',
  Done = 'DONE',
}

export interface OnboardingType {
  poll?: boolean;
  workspace?: boolean;
  calendar?: boolean;
  extension?: boolean;
  consent?: boolean;
  guides?: {
    home?: boolean;
    settings?: boolean;
    conversation2?: boolean;
  };
  checklist?:
    | true
    | {
        transcribe?: boolean;
        summarize?: boolean;
        followup?: boolean;
        invite?: boolean;
      };
  natalieNYGreetings?: boolean;
}

export interface FunnelType {
  [funnel_id: string]: {
    steps: { [question_id: string]: string[] }[];
  };
}

export interface MenuLink {
  title: string;
  icon?: TablerIconComponent | string;
  iconSize?: number | string;
  to?: LocationAsRelativeRaw;
}

export interface Identifiable {
  id: string | number;
}

export const Pricing: Record<string, WorkspacePricing> = {
  PERSONAL: 'PERSONAL',
  PERSONAL_PRO: 'PERSONAL_PRO',
  TEAM: 'TEAM',
  ENTERPRISE: 'ENTERPRISE',
};
export type Pricing = (typeof Pricing)[keyof typeof Pricing];

export enum AccountRole {
  ADMIN = 'ADMIN',
  MEMBER = 'MEMBER',
  LITE = 'LITE',
}

export enum SpaceRole {
  ADMIN = 'ADMIN',
  MEMBER = 'MEMBER',
}

export enum WorkspaceConversationEndEmailTarget {
  NOBODY = 'NOBODY',
  WORKSPACE_DOMAINS = 'WORKSPACE_DOMAINS',
  EVERYONE = 'EVERYONE',
}

export enum AIMessageDirection {
  INCOMING = 'INCOMING',
  OUTGOING = 'OUTGOING',
}

export enum AIMessageStatus {
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
  FULFILLED = 'FULFILLED',
}

export enum TodoItemStatus {
  SUGGESTED = 'SUGGESTED',
  IN_PROGRESS = 'IN_PROGRESS',
  DONE = 'DONE',
}

export enum ConversationStatus {
  SCHEDULED = 'SCHEDULED',
  ONGOING = 'ONGOING',
  FINISHED = 'FINISHED',
  CANCELED = 'CANCELED',
  MISSED = 'MISSED',
}

export enum AIAssistType {
  TODO_ITEMS = 'TODO_ITEMS',
  ACTION_ITEMS = 'ACTION_ITEMS',
  SUMMARY = 'SUMMARY',
  TASKS = 'TASKS',
  DECISIONS = 'DECISIONS',
  FOLLOW_UP = 'FOLLOW_UP',
  RECAP = 'RECAP',
}

export enum AIAssistStatus {
  INIT = 'INIT',
  FULFILLED = 'FULFILLED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
}

export enum BlockType {
  ACTION_ITEMS = 'ACTION_ITEMS',
  CHAT_MESSAGE = 'CHAT_MESSAGE',
  DECISIONS = 'DECISIONS',
  FOLLOW_UP = 'FOLLOW_UP',
  HIGHLIGHT = 'HIGHLIGHT',
  SCREENSHOT = 'SCREENSHOT',
  SUMMARY = 'SUMMARY',
  SUMMARY_BOOKMARK = 'SUMMARY_BOOKMARK',
  TASKS = 'TASKS',
  TOPIC = 'TOPIC',
  TRANSCRIPT_LINE = 'TRANSCRIPT_LINE',
  RECAP = 'RECAP',
}

export enum SubscriptionInterval {
  Month = 'month',
  Quarter = 'quarter',
  Year = 'year',
}

export enum OAuth2Provider {
  Google = 'GOOGLE',
}

export enum OAuth2Status {
  Authorized = 'AUTHORIZED',
  Failed = 'FAILED',
}

/**
 * @remarks Had to duplicate this rule from the BE
 * due to an issue with the enum import from the database
 * @see {@link file://./../../../trpc_backend/src/domain/workspaces/validation/workspace.rules.ts#AllowedEndEmailTargetByPricing}
 * @todo Remove this duplication once the importing issue is resolved
 */
export const AllowedEndEmailTargetByPricing = {
  [Pricing.PERSONAL]: [
    WorkspaceConversationEndEmailTarget.WORKSPACE_DOMAINS,
    WorkspaceConversationEndEmailTarget.EVERYONE,
  ],
  [Pricing.PERSONAL_PRO]: [
    WorkspaceConversationEndEmailTarget.WORKSPACE_DOMAINS,
    WorkspaceConversationEndEmailTarget.EVERYONE,
    WorkspaceConversationEndEmailTarget.NOBODY,
  ],
  [Pricing.TEAM]: [
    WorkspaceConversationEndEmailTarget.WORKSPACE_DOMAINS,
    WorkspaceConversationEndEmailTarget.EVERYONE,
    WorkspaceConversationEndEmailTarget.NOBODY,
  ],
  [Pricing.ENTERPRISE]: [
    WorkspaceConversationEndEmailTarget.WORKSPACE_DOMAINS,
    WorkspaceConversationEndEmailTarget.EVERYONE,
    WorkspaceConversationEndEmailTarget.NOBODY,
  ],
} as Record<WorkspacePricing, WorkspaceConversationEndEmailTarget[]>;
