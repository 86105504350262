import { onBeforeMount, ref, Ref, UnwrapRef } from 'vue';
import { RecycleScrollerInstance } from 'vue-virtual-scroller';
import { TranscriptLine } from '@/api/types';
import { useGetTranscript } from '@/api/transcript';
import { watch } from 'vue';
import { onUnmounted } from 'vue';

interface ExtendedTranscriptLine extends TranscriptLine {
  originalText: string;
  isSearched: boolean;
}

export interface TranscriptHook {
  scroller: Ref<RecycleScrollerInstance | null>;
  search: Ref<string>;
  searchCursor: Ref<number>;
  searchables: Ref<number[]>;
  scrollPrev: () => void;
  scrollNext: () => void;
  highlightsOnly: Ref<boolean>;
  toggleHighlightsOnly: () => void;
  transcript: Ref<ExtendedTranscriptLine[]>;
  isTranscriptLoading: Ref<boolean>;
}

const transcript = ref<ExtendedTranscriptLine[]>([]);

/**
 * Should be provided as a ref to the RecycleScroller component
 */
const scroller = ref<UnwrapRef<TranscriptHook['scroller']>>(null);

const search = ref('');
const searchCursor = ref(0);
const searchables = ref<number[]>([]);

const scrollPrev = (): void => {
  if (scroller.value) {
    searchCursor.value =
      searchCursor.value === 0
        ? searchables.value.length - 1
        : searchCursor.value - 1;

    scroller.value.scrollToItem(searchables.value[searchCursor.value]);
  }
};

const scrollNext = (): void => {
  if (scroller.value) {
    searchCursor.value = (searchCursor.value + 1) % searchables.value.length;
    scroller.value.scrollToItem(searchables.value[searchCursor.value]);
  }
};

const highlightsOnly = ref(false);
const toggleHighlightsOnly = (): void => {
  highlightsOnly.value = !highlightsOnly.value;
};

const markText = (
  text: string,
): Pick<ExtendedTranscriptLine, 'text' | 'isSearched'> => {
  let isSearched = false;

  if (!search.value) {
    return { text, isSearched };
  }

  const maybeMarkedText = text.replace(
    new RegExp(search.value, 'gi'),
    (match) => {
      isSearched = true;
      return `<mark>${match}</mark>`;
    },
  );

  return { text: maybeMarkedText, isSearched };
};

const computeTranscriptData = (
  rawTranscript: Ref<TranscriptLine[] | undefined>,
): void => {
  const searchablesList: number[] = [];
  transcript.value =
    rawTranscript.value?.map((l, i) => {
      let maybeMarkedText = l.text;
      let isSearched = false;

      if (search.value && !highlightsOnly.value) {
        const result = markText(maybeMarkedText);
        maybeMarkedText = result.text;
        isSearched = result.isSearched;
      }

      if (
        isSearched ||
        (highlightsOnly.value && !search.value && l.isHighlighted)
      ) {
        searchablesList.push(i);
      }

      return {
        ...l,
        originalText: l.text,
        text: maybeMarkedText,
        isSearched,
      };
    }) ?? [];
  searchables.value = searchablesList;
};

export const useTranscript = (): TranscriptHook => {
  const { data: rawTranscript, isLoading: isTranscriptLoading } =
    useGetTranscript();

  /**
   * @summary Recompute transcript data to mark new search results.
   * Reset cursor and scroll to the first search result
   * if scroller is available and search or highlights are active
   */
  watch([rawTranscript, search, highlightsOnly], () => {
    computeTranscriptData(rawTranscript);
    searchCursor.value = 0;
    if (scroller.value && (search.value || highlightsOnly.value)) {
      scroller.value.scrollToItem(searchables.value[0]);
    }
  });

  onBeforeMount(() => computeTranscriptData(rawTranscript));
  onUnmounted(() => {
    search.value = '';
    highlightsOnly.value = false;
  });

  return {
    scroller,
    search,
    searchCursor,
    searchables,
    scrollPrev,
    scrollNext,
    highlightsOnly,
    toggleHighlightsOnly,
    transcript,
    isTranscriptLoading,
  };
};
