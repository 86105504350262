import { SubscriptionInterval } from '@/api/types';

export const MONTHS_IN_PERIOD: Record<SubscriptionInterval, number> = {
  [SubscriptionInterval.Month]: 1,
  [SubscriptionInterval.Quarter]: 3,
  [SubscriptionInterval.Year]: 12,
};

const folderNames = [
  'Professional',
  'Innovative',
  'Strategic',
  'Dynamic',
  'Efficient',
  'Productive',
  'Collaborative',
  'Insightful',
  'Resourceful',
  'Pioneering',
  'Optimize',
  'Empower',
  'Synergy',
  'Solutions',
  'Progress',
  'Growth',
  'Impact',
  'Excellence',
  'Influence',
  'Advantage',
  'Inspiration',
  'Achievements',
];

export const getRandomFolderName = () => {
  const randomIndex = Math.floor(Math.random() * folderNames.length);
  return `${folderNames[randomIndex]} Folder`;
};
