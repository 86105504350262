<script setup lang="ts">
import { ref, defineModel, watch } from 'vue';
import { syncRef } from '@vueuse/core';
import {
  SquareRoundedIcon,
  SquareRoundedCheckFilledIcon,
} from 'vue-tabler-icons';
import Icon from '@/components/common/atoms/Icon.vue';

const model = defineModel<boolean | null>({
  required: false,
  default: null,
});
const emit = defineEmits<{ (e: 'change'): void }>();
const props = defineProps<{
  value?: boolean;
  manual?: boolean;
  disabled?: boolean;
  label?: string;
  iconClass?: string;
}>();

const localModel = ref(model.value ?? props.value);
syncRef(localModel, model, { direction: 'ltr' });

watch(
  () => props.value,
  (newValue) => {
    localModel.value = newValue;
  },
);

const toggle = () => {
  if (props.disabled) {
    return;
  }

  props.manual ? emit('change') : (localModel.value = !localModel.value);
};
</script>
<template>
  <label
    :class="`app-checkbox flex items-center gap-x-2 cursor-${disabled ? 'default' : 'pointer'}`"
    @click="toggle"
  >
    <slot v-if="localModel" name="checked">
      <Icon
        :src="SquareRoundedCheckFilledIcon"
        :class="`app-checkbox__icon-${disabled ? 'disabled' : 'active'} ${iconClass}`"
      />
    </slot>
    <slot v-else name="unchecked">
      <Icon
        :src="SquareRoundedIcon"
        :class="`app-checkbox__icon${disabled ? '-disabled' : ''} ${iconClass}`"
      />
    </slot>
    <span v-if="label" class="typo-body1">
      {{ label }}
    </span>
    <slot v-else name="label" />
  </label>
</template>

<style lang="scss">
.icon.app-checkbox__icon {
  @apply w-6 h-6 text-primary-100;

  &:hover:not(&-disabled),
  &-active {
    @apply text-primary;
  }

  &-disabled {
    @apply text-grey-100;
  }
}
</style>
