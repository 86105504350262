<script setup lang="ts">
import { TrashIcon } from 'vue-tabler-icons';
import { useDeleteAccount } from '@/components/settings/Personal/hooks';

const {
  isDeleteAccountConfirmationOpen,
  closeAllPopups,
  openDeleteAccountSuccess,
} = useDeleteAccount();
</script>
<template>
  <v-dialog
    v-model="isDeleteAccountConfirmationOpen"
    persistent
    max-width="544"
  >
    <div
      class="flex flex-col gap-y-4 lg:gap-y-8 bg-white w-full overflow-x-hidden rounded-2xl p-4 lg:p-8 max-h-[800px] border border-grey-20 border-solid relative"
    >
      <ClosePopupButton :on-click="closeAllPopups" />
      <div class="flex flex-col gap-y-4">
        <h4 class="max-lg:typo-h5 lg:text-2xl lg:font-bold leading-normal">
          This action cannot be undone
        </h4>
        <p class="typo-body1 font-normal">
          Think twice now! Deleting your account will result in the permanent
          loss of all associated information, and it cannot be undone. To
          proceed with account deletion, you must transfer admin rights to
          another Noty user, which can be done in the Members settings.
        </p>
      </div>
      <div
        class="grid grid-cols-2 md:flex md:justify-end md:items-center gap-x-3 md:gap-x-4"
      >
        <v-btn
          variant="text"
          color="error-functional-500"
          height="auto"
          class="typo-body1 font-semibold px-[2px]"
          @click="openDeleteAccountSuccess"
        >
          <template #prepend>
            <Icon :src="TrashIcon" class="w-4 h-4 text-alerts-error-500" />
          </template>
          Delete account
        </v-btn>
        <v-btn
          color="primary"
          elevation="0"
          height="36"
          class="typo-body1 font-semibold"
          @click="closeAllPopups"
        >
          Give it another try
        </v-btn>
      </div>
    </div>
  </v-dialog>
</template>
