import { computed, onMounted, ref } from 'vue';
import { useDisplay } from 'vuetify';
import type { ComputedRef, Ref } from 'vue';

interface SidebarSizeHook {
  isCollapsed: Ref<boolean>;
  rail: Ref<boolean>;
  isDesktop: ComputedRef<boolean>;
  toggleCollapse: () => void;
  toggleRail: () => void;
}

const isCollapsed = ref(true);
const rail = ref(false);

export const useSidebarSize = (): SidebarSizeHook => {
  const { lgAndUp } = useDisplay();

  const isDesktop = computed(() => lgAndUp.value);

  const toggleCollapse = (): void => {
    isCollapsed.value = !isCollapsed.value;
    if (!isDesktop.value && rail.value) {
      rail.value = false;
    }
  };

  const toggleRail = (): void => {
    rail.value = !rail.value;
  };

  onMounted(() => {
    if (!isDesktop.value) {
      isCollapsed.value = false;
    }
  });

  return {
    isCollapsed,
    rail,
    isDesktop,
    toggleCollapse,
    toggleRail,
  };
};
