<script setup lang="ts">
import MainMenu from './MainMenu.vue';
import SettingsMenu from './SettingsMenu.vue';
import WorkspaceSelect from './WorkspaceSelect.vue';
import ReferralProgram from './components/ReferralProgram.vue';
import { TopBar } from './components';
import { computed } from 'vue';
import { useSidebarSize } from './hooks';
import { Menu2Icon, XIcon } from 'vue-tabler-icons';
import { IMAGES } from '@/constants/assets';

const { isCollapsed, rail, isDesktop, toggleCollapse } = useSidebarSize();

const barLocation = computed(() => (isDesktop.value ? 'start' : 'end'));
</script>

<template>
  <v-app-bar v-if="!isDesktop" height="52" class="shadow-none border-none">
    <v-toolbar-title>
      <RouterLink to="/">
        <img
          :src="IMAGES.NOTY_FULL_LOGO"
          alt="Noty.ai"
          class="w-[62px] h-[20px] object-cover"
        />
      </RouterLink>
    </v-toolbar-title>
    <v-spacer />
    <v-app-bar-nav-icon
      class="sidebar-menu__toggle-icon"
      :ripple="false"
      @click="toggleCollapse"
    >
      <Icon
        :src="isCollapsed ? XIcon : Menu2Icon"
        size="20"
        :class="isCollapsed ? 'text-black' : 'text-primary'"
      />
    </v-app-bar-nav-icon>
  </v-app-bar>
  <v-navigation-drawer
    v-model="isCollapsed"
    class="sidebar-menu top-[51px] xl:top-2 max-xl:py-2 xl:pt-6 xl:pb-4 px-2 max-xl:w-[304px] xl:max-w-[256px] bg-white border-none xl:rounded-lg"
    :permanent="isDesktop"
    :temporary="!isDesktop"
    :rail="isDesktop ? rail : false"
    :location="barLocation"
    width="264"
    rail-width="72"
  >
    <div class="flex flex-col justify-between gap-y-6 lg:gap-y-8 h-full">
      <div class="flex flex-col gap-y-8">
        <TopBar v-if="isDesktop" />
        <div class="flex flex-col gap-y-4">
          <WorkspaceSelect />
          <MainMenu />
        </div>
      </div>
      <div class="flex flex-col gap-y-4 lg:gap-y-8">
        <SettingsMenu />
        <ReferralProgram />
      </div>
    </div>
  </v-navigation-drawer>
</template>

<style lang="scss">
.sidebar-menu {
  left: unset !important;

  &.v-navigation-drawer--rail {
    max-width: 64px !important;
    padding: 24px 8px 16px !important;
  }

  &__toggle-icon .v-btn__overlay {
    @apply bg-transparent;
  }
}
</style>
