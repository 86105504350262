import { computed } from 'vue';
import { useMutation, useQuery, useQueryClient } from '@tanstack/vue-query';
import { api } from '@/api';
import { useCurrentConversationId } from '@/store/hooks';
import { UpdateTranscriptLinesInput, TranscriptLine } from './types';

export const useGetTranscript = () => {
  const conversationId = useCurrentConversationId();

  return useQuery({
    enabled: computed(() => !!conversationId.value),
    queryKey: ['transcript', conversationId],
    queryFn: () =>
      api.transcript.getTranscript.query({
        conversationId: conversationId.value!,
      }),
  });
};

export const useToggleHighlight = () => {
  const conversationId = useCurrentConversationId();
  const queryClient = useQueryClient();
  const queryKey = ['transcript', conversationId];

  return useMutation({
    mutationFn: (lineId: string) =>
      api.transcript.toggleHighlight.mutate({ id: lineId }),
    onMutate: async (lineId) => {
      await queryClient.cancelQueries({ queryKey });

      let history: TranscriptLine[] | undefined;
      queryClient.setQueryData(
        queryKey,
        (prev: TranscriptLine[] | undefined) => {
          history = prev;
          return prev?.map((l) =>
            l.id === lineId ? { ...l, isHighlighted: !l.isHighlighted } : l,
          );
        },
      );

      return { history };
    },
    onError: (error, variables, context) => {
      queryClient.setQueryData(queryKey, context?.history);
    },
    onSettled: () => {
      queryClient.invalidateQueries(queryKey);
    },
  });
};

export const useUpdateTranscriptLines = () => {
  const conversationId = useCurrentConversationId();
  const queryClient = useQueryClient();
  const queryKey = ['transcript', conversationId];

  return useMutation({
    mutationFn: (input: UpdateTranscriptLinesInput) =>
      api.transcript.updateLinesText.mutate(input),
    onMutate: async (input) => {
      await queryClient.cancelQueries({ queryKey });
      const inputMap = input.reduce(
        (acc, { id, text }) => ({
          ...acc,
          [id]: text,
        }),
        {} as Record<string, string>,
      );

      let history: TranscriptLine[] | undefined;
      queryClient.setQueryData(
        queryKey,
        (prev: TranscriptLine[] | undefined) => {
          history = prev;
          return prev?.map((l) => ({
            ...l,
            text: inputMap[l.id] ?? l.text,
          }));
        },
      );

      return { history };
    },
    onError: (error, variables, context) => {
      queryClient.setQueryData(queryKey, context?.history);
    },
    onSettled: () => {
      queryClient.invalidateQueries(queryKey);
    },
  });
};

export const useDeleteTranscriptLine = () => {
  const conversationId = useCurrentConversationId();
  const queryClient = useQueryClient();
  const queryKey = ['transcript', conversationId];

  return useMutation({
    mutationFn: (lineId: string) =>
      api.transcript.deleteLine.mutate({ id: lineId }),
    onMutate: async (lineId) => {
      await queryClient.cancelQueries({ queryKey });

      let history: TranscriptLine[] | undefined;
      queryClient.setQueryData(
        queryKey,
        (prev: TranscriptLine[] | undefined) => {
          history = prev;
          return prev?.filter((l) => l.id !== lineId);
        },
      );

      return { history };
    },
    onError: (error, variables, context) => {
      queryClient.setQueryData(queryKey, context?.history);
    },
    onSettled: () => {
      queryClient.invalidateQueries(queryKey);
    },
  });
};
