import 'vuetify/styles';
import { createVuetify, VuetifyOptions } from 'vuetify';
import { aliases, mdi } from 'vuetify/iconsets/mdi-svg';

export const notyTheme: VuetifyOptions = {
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi,
    },
  },
  defaults: {
    VBtn: { class: 'text-none tracking-normal' },
    VTextField: { variant: 'outlined' },
    VBreadcrumbsItem: { class: 'typo-body2' },
  },
  theme: {
    defaultTheme: 'notyTheme',
    themes: {
      notyTheme: {
        dark: false,
        colors: {
          primary: '#625CF5',
          secondary: '#EFE6FF',
          error: '#ef4459',
          red: '#F44336',
          green: '#4CAF50',
          blue: '#2196F3',
          pink: '#F06DA8',
          'blue-secondary': '#2388FF',
          'green-bright-secondary': '#33DA9D',
          yellow: '#FFEB3B',
          orange: '#FF9800',
          'on-background': '#263238',
          'on-surface': '#263238',
          'blue-arctic': '#38BDF8',
          'red-carrot': '#FFA2A4',
          'error-functional-500': '#EF4459',
          'error-functional-600': '#BF3647',
        },
        variables: {
          'high-emphasis-opacity': '1',
        },
      },
    },
  },
};
export const vuetify = createVuetify(notyTheme);
