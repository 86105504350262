<script setup lang="ts">
import { PencilIcon } from 'vue-tabler-icons';
import { SidebarItem, GmeetLangsAutocomplete } from '.';
import { useGmeetLang, useSidebarSize } from '../hooks';
import LangIcon from '@/assets/icons/lang.svg';
import Icon from '@/components/common/atoms/Icon.vue';

const { showLangsAutocomplete, langTitle } = useGmeetLang();

const { rail } = useSidebarSize();
</script>

<template>
  <v-menu v-model="showLangsAutocomplete" :close-on-content-click="false">
    <template #activator="{ props }">
      <SidebarItem
        v-bind="props"
        :class="['sidebar-lang h-12', { 'sidebar-lang__rail': rail }]"
      >
        <template #prepend>
          <div
            class="sidebar-link__icon flex align-center justify-center w-6 h-6 rounded"
          >
            <Icon :src="LangIcon" class="w-5 h-5" stroke-width="1.5" />
          </div>
        </template>
        <template #append>
          <Icon :src="PencilIcon" size="16" class="text-primary" />
        </template>
        <template #default>
          <div class="flex flex-col items-start grow text-black">
            <div class="typo-body1 font-bold">Transcription Language</div>
            <div class="typo-overline">
              {{ langTitle }}
            </div>
          </div>
        </template>
      </SidebarItem>
    </template>
    <template #default>
      <GmeetLangsAutocomplete :class="{ 'sidebar-lang-autocomplete': rail }" />
    </template>
  </v-menu>
</template>

<style lang="scss">
.sidebar-lang {
  .v-btn__content {
    @apply grow;
  }
}
.sidebar-lang__rail {
  .v-btn__append,
  .v-btn__content {
    @apply hidden;
  }

  &[aria-expanded='true'] .v-btn__prepend {
    @apply bg-primary text-white rounded;
  }
}

.sidebar-lang-autocomplete {
  @apply min-w-[240px];
}
</style>
