<script setup lang="ts">
import Confetti from '@/components/onboarding/Confetti.vue';
import { ref } from 'vue';
import { IMAGES } from '@/constants/assets';
import { onboardingMap } from '@/components/onboarding/onboarding.config';
import { OnboardingStepEnum } from '@/utils/onboarding';

const showModal = ref(true);
</script>

<template>
  <v-dialog :model-value="showModal" persistent fullscreen>
    <div
      class="done-step-popup m-auto flex flex-col align-center gap-y-4 rounded-[28px] bg-white"
    >
      <img :src="IMAGES.HI" width="48" height="48" alt="" />
      <h2
        class="font-geologica text-[24px] sm:text-[36px] leading-tight sm:leading-[48px] font-bold"
      >
        {{ onboardingMap[OnboardingStepEnum.DONE].title }}
      </h2>
    </div>
    <Confetti />
  </v-dialog>
</template>

<style scoped>
.done-step-popup {
  z-index: 2;
  min-width: 343px;
  padding: 32px 24px;

  @screen md {
    padding: 56px 64px;
  }
}
</style>
