<script setup lang="ts">
import { DoorExitIcon } from 'vue-tabler-icons';
import { useLeaveCurrentWorkspace } from '@/components/settings/Workspace/hooks';

const {
  isLeaveCurrentWorkspaceConfirmationOpen,
  isLeaving,
  toggleLeaveCurrentWorkspaceConfirmation,
  leaveCurrentWorkspace,
} = useLeaveCurrentWorkspace();
</script>
<template>
  <v-dialog
    v-model="isLeaveCurrentWorkspaceConfirmationOpen"
    persistent
    max-width="544"
  >
    <div
      class="flex flex-col gap-y-4 lg:gap-y-8 bg-white w-full overflow-x-hidden rounded-2xl p-4 lg:p-8 max-h-[800px] border border-grey-20 border-solid relative"
    >
      <ClosePopupButton
        :on-click="toggleLeaveCurrentWorkspaceConfirmation"
        :disabled="isLeaving"
      />
      <div class="flex flex-col gap-y-4">
        <h4 class="max-lg:typo-h5 lg:text-2xl lg:font-bold leading-normal">
          Please confirm that you are leaving
        </h4>
        <p class="typo-body1 font-normal">
          We don't want you to miss out on anything, so please confirm that you
          are leaving the workspace and understand that you won't have access to
          the data.
        </p>
      </div>
      <div
        class="grid grid-cols-2 md:flex md:justify-end md:items-center gap-x-3 md:gap-x-4"
      >
        <v-btn
          variant="text"
          color="error-functional-500"
          height="auto"
          class="typo-body1 font-semibold px-[2px]"
          :disabled="isLeaving"
          :loading="isLeaving"
          @click="leaveCurrentWorkspace"
        >
          <template #prepend>
            <Icon :src="DoorExitIcon" class="w-4 h-4 text-alerts-error-500" />
          </template>
          Leave anyway
        </v-btn>
        <v-btn
          color="primary"
          elevation="0"
          height="36"
          class="typo-body1 font-semibold"
          :disabled="isLeaving"
          @click="toggleLeaveCurrentWorkspaceConfirmation"
        >
          Cancel
        </v-btn>
      </div>
    </div>
  </v-dialog>
</template>
