<script setup lang="ts">
import { useSidebarSize } from '../hooks';
import FrameIcon from '@/assets/icons/frame.svg';
import { IMAGES } from '@/constants/assets';

const { rail, isDesktop, toggleRail } = useSidebarSize();

const onClick = () => {
  if (isDesktop.value) {
    toggleRail();
  }
};
</script>

<template>
  <div
    :class="`flex items-center ${rail ? 'justify-center' : 'justify-between'}`"
  >
    <RouterLink v-show="!rail" to="/">
      <img
        :src="IMAGES.NOTY_FULL_LOGO"
        alt="Noty.ai"
        class="w-[72px] h-[23px] object-cover"
      />
    </RouterLink>
    <Icon
      :src="FrameIcon"
      class="cursor-pointer text-primary"
      :size="rail ? 20 : 16"
      @click="onClick"
    />
  </div>
</template>
