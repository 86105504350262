<script setup lang="ts">
import { useDeleteAccount } from '@/components/settings/Personal/hooks';

const {
  isDeleteAccountSuccessOpen,
  isDeleting,
  closeAllPopups,
  deleteAccount,
} = useDeleteAccount();
</script>
<template>
  <v-dialog v-model="isDeleteAccountSuccessOpen" persistent max-width="544">
    <div
      class="flex flex-col gap-y-4 lg:gap-y-8 bg-white w-full overflow-x-hidden rounded-2xl p-4 lg:p-8 max-h-[800px] border border-grey-20 border-solid relative"
    >
      <ClosePopupButton :on-click="closeAllPopups" :disabled="isDeleting" />
      <div class="flex flex-col gap-y-4">
        <h4 class="max-lg:typo-h5 lg:text-2xl lg:font-bold leading-normal">
          Sorry to see you go :(
        </h4>
        <p class="typo-body1 font-normal">
          Your account will be deleted, and data will be purged in a matter of a
          few days. Thank you for using Noty.ai. We will miss you!
        </p>
      </div>
      <div class="flex justify-end">
        <v-btn
          color="primary"
          elevation="0"
          height="36"
          class="typo-body1 font-semibold max-sm:w-full"
          :disabled="isDeleting"
          :loading="isDeleting"
          @click="deleteAccount"
        >
          Bye-Bye
        </v-btn>
      </div>
    </div>
  </v-dialog>
</template>
