<script setup lang="ts">
import { ChevronDownIcon, PlusIcon, CrownIcon } from 'vue-tabler-icons';
import { VSkeletonLoader } from 'vuetify/labs/VSkeletonLoader';
import { ROUTE_NAMES } from '@/constants/routeNames';
import { useSidebarSize, useWorkspacesMenu } from './hooks';
import CubesIcon from '@/assets/icons/cubes.svg';

const {
  isDropdownOpen,
  currentWorkspace,
  areWorkspacesLoading,
  workspaces,
  primaryWorkspaceId,
  changeCurrentWorkspace,
} = useWorkspacesMenu();
const { rail } = useSidebarSize();
</script>

<template>
  <v-skeleton-loader
    v-if="areWorkspacesLoading || !currentWorkspace"
    class="rounded w-full h-10"
  />
  <v-menu v-else v-model="isDropdownOpen" location="bottom">
    <template #activator="{ props: menu }">
      <div
        v-if="rail"
        class="flex align-center justify-center w-12 h-12 border border-primary-50 cursor-pointer rounded"
        v-bind="menu"
      >
        <Icon :src="CubesIcon" size="20" stroke-width="1.5" />
      </div>
      <v-btn
        v-else
        v-bind="menu"
        elevation="0"
        color="transparent"
        height="40"
        class="workspace-select border-primary-50"
        :ripple="{ class: 'text-primary-50' }"
      >
        <template #default>
          <span>{{ currentWorkspace.name }}</span>
        </template>
        <template #append>
          <Icon
            :src="ChevronDownIcon"
            class="w-4 h-4 text-primary"
            :class="{ 'rotate-180': isDropdownOpen }"
          />
        </template>
      </v-btn>
    </template>
    <v-list elevation="0" class="py-0 shadow-soft rounded-[8px]">
      <v-list-item
        v-for="{ id, name } in workspaces"
        :key="id"
        v-tooltip="primaryWorkspaceId === id && 'This is a primary workspace'"
        class="workspace-select__list-item"
      >
        <v-btn
          elevation="0"
          variant="text"
          :ripple="{ class: 'text-primary' }"
          class="workspace-select__list-item__btn"
          @click="changeCurrentWorkspace(id)"
        >
          <template #default>
            <span>{{ name }}</span>
          </template>
          <template v-if="primaryWorkspaceId === id" #append>
            <CrownIcon class="w-4 h-4 text-grey-90" />
          </template>
        </v-btn>
      </v-list-item>
      <v-list-item
        :class="[
          'workspace-select__list-item border-t border-solid border-primary-25',
          { 'min-w-[240px]': rail },
        ]"
      >
        <v-btn
          elevation="0"
          variant="text"
          :ripple="{ class: 'text-primary' }"
          class="workspace-select__list-item__btn"
          :to="{ name: ROUTE_NAMES.CREATE_WORKSPACE }"
        >
          <template #append>
            <div
              class="flex items-center justify-center w-5 h-5 bg-primary-50 rounded"
            >
              <Icon :src="PlusIcon" class="text-primary" size="12" />
            </div>
          </template>
          <template #default>
            <span>Create a workspace</span>
          </template>
        </v-btn>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<style>
.workspace-select {
  @apply flex items-center gap-x-2 p-2 border border-solid border-grey-10 rounded-[8px];
}
.workspace-select[aria-expanded='true'] {
  @apply bg-primary-25 !important;
}

.workspace-select .v-btn__content {
  @apply flex justify-start grow typo-body1 font-semibold overflow-ellipsis;
}

.workspace-select__list-item {
  @apply p-0 min-h-0 !important;
}

.workspace-select__list-item__btn {
  @apply flex items-center gap-x-2 p-2 w-full rounded-none hover:bg-primary-50;
}

.workspace-select__list-item__btn .v-btn__content {
  @apply flex justify-start grow typo-body1 overflow-ellipsis;
}

.workspace-select .v-btn__overlay,
.workspace-select__list-item__btn .v-btn__overlay {
  @apply bg-transparent;
}

.workspace-select .v-btn__prepend,
.workspace-select .v-btn__append,
.workspace-select__list-item__btn .v-btn__prepend,
.workspace-select__list-item__btn .v-btn__append {
  @apply m-0;
}
</style>
