<script setup lang="ts">
import { computed, reactive, ref } from 'vue';
import { useUser } from '@/auth/use-session';
import BtnWithAModal from '@/components/common/organisms/BtnWithAModal.vue';
import VCopyButton from '@/components/common/organisms/VCopyButton.vue';
import EmailMultipleInput from '@/components/common/molecules/EmailMultipleInput.vue';
import {
  validationEmail,
  validationRequired,
  validationMaxLength,
} from '@/utils/validation/text-field';
import { GiftIcon } from 'vue-tabler-icons';
import { invite } from '@/api/users';
import { showError, showMessage } from '@/utils/alert';
import { useMutation } from '@tanstack/vue-query';
import { useRefFromRouterQuery } from '@/store/hooks/UseRefFromRouterQuery';
import { useSidebarSize } from '@/components/SidebarMenu/hooks';

const { data: user } = useUser();
const { rail } = useSidebarSize();

const inviteLink = computed(() => {
  return `${window.location.origin}/sign-in?referralCode=${user.value?.id}`;
});

const isModalOpened = useRefFromRouterQuery<boolean>(
  'showReferral',
  'true',
  true,
  false,
);

const state = reactive({
  isFormValid: false,
  emails: [],
});

const childComponentRef = ref();
const { isLoading, mutate: sendInvites } = useMutation({
  mutationFn: (emails: string[]) => invite(emails),
  onError(error: Error) {
    showError(error);
  },
  onSuccess() {
    showMessage('Request sent');
    childComponentRef.value.closeModal();
  },
});
const emailsInputRef = ref(null);
const sendInvitesHandler = (emails: string[]) => {
  if (!state.isFormValid) {
    emailsInputRef.value?.$el?.querySelector('input')?.focus();
    return;
  }
  sendInvites(emails);
};
</script>

<template>
  <BtnWithAModal
    ref="childComponentRef"
    :btn-text="
      rail ? '' : 'Send a referral to a friend Get 1 Week of Noty Pro!'
    "
    btn-class="w-[198px] typo-body1 font-semibold"
    variant="elevated"
    color="primary"
    :is-open="isModalOpened"
  >
    <template #btn-append>
      <Icon
        :src="GiftIcon"
        :size="rail ? 64 : 54"
        :stroke-width="rail ? 2 : 1"
        :class="[
          'absolute text-[#A79AFC] -rotate-[15deg]',
          {
            'right-[-12px] top-[20px]': !rail,
          },
          {
            'top-[7px] left-0 opacity-20': rail,
          },
        ]"
      />
    </template>
    <template #modal>
      <h1 class="typo-h1">Sharing is caring!</h1>
      <p class="typo-body1 mb-8">Refer a friend and get 7 days of Noty Pro!</p>
      <h2 class="mb-1">How it works:</h2>
      <ul>
        <li>1. Add their email address or send them the below link directly</li>
        <li>2. Your friend signs up with us</li>
        <li>3. You get 7 days of Noty Pro</li>
        <li>4. Repeat and Get even More!</li>
      </ul>
      <h2 class="mt-6 mb-2 basis full">Send the email to:</h2>
      <v-form
        v-model="state.isFormValid"
        class="w-full flex"
        validate-on="blur"
        @submit.prevent="sendInvitesHandler(state.emails)"
      >
        <EmailMultipleInput
          ref="emailsInputRef"
          v-model="state.emails"
          placeholder="Type emails to send invite letters.."
          :rules="[
            validationEmail,
            validationRequired,
            (emails: string[]) => validationMaxLength(emails, 10),
          ]"
        />
        <v-btn
          variant="flat"
          color="primary"
          type="submit"
          :loading="isLoading"
          text="Send"
          class="h-[38px] max-w-[60px]"
        />
      </v-form>
      <h2 class="my-2">Or copy the link below to send them directly:</h2>
      <div class="flex">
        <div class="px-3 py-2 bg-grey-10 rounded text-sm break-all mr-1">
          {{ inviteLink }}
        </div>
        <VCopyButton
          :value="inviteLink"
          variant="flat"
          color="primary"
          no-icon
          class="max-w-[60px] w-full"
        />
      </div>
    </template>
  </BtnWithAModal>
</template>
