<script setup lang="ts">
import { TrashIcon } from 'vue-tabler-icons';
import { NameInput, ColorPicker, InviteMembers } from './components';
import { useManageFolder } from '@/components/settings/Folders/hooks';

const {
  isOpen,
  openDeleteConfirmation,
  isActionInProgress,
  isSaving,
  selectedFolder,
  folderData,
  close,
  save,
} = useManageFolder();
</script>
<template>
  <v-dialog
    v-model="isOpen"
    persistent
    max-width="568"
    class="manage-folder-popup"
  >
    <div
      class="flex flex-col gap-y-4 lg:gap-y-6 bg-white w-full rounded-2xl p-4 lg:p-8 border border-grey-20 border-solid"
    >
      <h4 class="max-lg:typo-h5 lg:text-2xl lg:font-bold leading-normal">
        {{ selectedFolder ? 'Edit' : 'Create' }} folder
      </h4>
      <NameInput v-model="folderData.name" />
      <ColorPicker v-model="folderData.color" />
      <InviteMembers />
      <div class="flex max-md:flex-col md:items-center gap-4 justify-between">
        <div>
          <v-btn
            v-if="selectedFolder"
            variant="text"
            color="error-functional-500"
            height="21"
            class="manage-folder__text-btn"
            :disabled="isActionInProgress"
            @click="openDeleteConfirmation"
          >
            <template #prepend>
              <Icon :src="TrashIcon" class="w-4 h-4 text-alerts-error-500" />
            </template>
            Delete folder
          </v-btn>
        </div>
        <div
          class="grid grid-cols-2 md:flex md:justify-end md:items-center gap-x-3 md:gap-x-4"
        >
          <v-btn
            variant="text"
            color="primary"
            height="36"
            class="px-4 typo-body1 font-semibold"
            :disabled="isActionInProgress"
            @click="close"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            elevation="0"
            height="36"
            class="px-[10px] typo-body1 font-semibold grow"
            :disabled="isActionInProgress"
            :loading="isSaving"
            @click="save"
          >
            Save
          </v-btn>
        </div>
      </div>
    </div>
  </v-dialog>
</template>
<style>
.manage-folder-popup__input input {
  @apply px-2 py-[7px] typo-body1 text-black;
}

.manage-folder__text-btn {
  @apply p-0 typo-body1 font-semibold;
}

.manage-folder__text-btn .v-btn__prepend {
  @apply ml-0;
}
</style>
