<script setup lang="ts">
import { VideoIcon } from 'vue-tabler-icons';
import { ROUTE_NAMES } from '@/constants/routeNames';
import { MenuLink } from '@/api/types';
import { FoldersDropdown } from './components';
import SidebarLink from '@/components/SidebarMenu/components/SidebarLink.vue';

const links: MenuLink[] = [
  {
    title: 'Meetings',
    icon: VideoIcon,
    to: { name: ROUTE_NAMES.HOME },
  },
];
</script>

<template>
  <div class="main-menu flex flex-col gap-y-2">
    <SidebarLink
      v-for="{ title, icon, to } in links"
      :key="title"
      :title="title"
      :to="to"
      :icon="icon"
    />
    <FoldersDropdown />
  </div>
</template>

<style lang="scss">
.v-btn__prepend,
.v-btn__append {
  @apply m-0;
}
</style>
