<script setup lang="ts">
import { RouterLink, useRoute } from 'vue-router';
import { VSkeletonLoader } from 'vuetify/labs/VSkeletonLoader';
import { PlusIcon, FoldersIcon } from 'vue-tabler-icons';
import { useFoldersMenu, useSidebarSize } from '../hooks';
import { useCurrentWorkspaceId } from '@/auth/use-workspace';
import { ROUTE_NAMES } from '@/constants/routeNames';
import { computed } from 'vue';

const { folders, openedPanel, isReadonly, toggleReadonlyState, createFolder } =
  useFoldersMenu();
const workspaceId = useCurrentWorkspaceId();

const route = useRoute();
const { rail, isDesktop } = useSidebarSize();

const isFoldersOpened = computed(() => typeof openedPanel.value === 'number');

const onClickOutside = () => {
  if (isDesktop.value && rail.value) {
    openedPanel.value = undefined;
  }
};
</script>

<template>
  <v-expansion-panels v-model="openedPanel" :readonly="isReadonly">
    <v-expansion-panel
      v-click-outside="onClickOutside"
      elevation="0"
      hide-actions
      readonly
      :class="[
        'folders-dropdown bg-transparent gap-y-1 hover:bg-primary-50',
        { 'rail-panel': rail },
      ]"
      :ripple="{ class: 'text-primary-50' }"
    >
      <template #title>
        <div
          :class="`flex items-center gap-x-2 h-12 px-2 py-[9px] w-full z-[2] ${rail ? 'justify-center' : 'justify-between'}`"
        >
          <div
            class="folders-dropdown__title-icon flex align-center justify-center w-6 h-6 rounded"
          >
            <Icon
              :src="FoldersIcon"
              :size="rail ? (isFoldersOpened ? 16 : 20) : 16"
            />
          </div>
          <template v-if="!rail">
            <p
              class="folders-dropdown__title grow text-left typo-body1 font-bold"
            >
              Folders
            </p>
            <div
              class="flex align-center justify-center w-5 h-5 bg-primary-50 cursor-pointer rounded"
            >
              <Icon
                class="text-primary"
                size="12"
                :src="PlusIcon"
                @mouseover="toggleReadonlyState"
                @mouseout="toggleReadonlyState"
                @click="createFolder"
              />
            </div>
          </template>
        </div>
      </template>
      <template #text>
        <div
          v-if="!folders"
          class="flex flex-col items-end gap-y-[2px] pt-[2px]"
        >
          <v-skeleton-loader v-for="i in 3" :key="i" class="w-5/6 h-7" />
        </div>
        <template v-else>
          <RouterLink
            v-for="{ id, name } in folders"
            :key="id"
            :to="{
              name: ROUTE_NAMES.CONVERSATIONS,
              params: { workspaceId },
              query: { spaceId: id },
            }"
          >
            <div
              class="folder-link flex flex-row items-center justify-between gap-x-2 h-12 py-[6px] pl-10 pr-3 typo-body1 text-ellipsis cursor-pointer hover:bg-primary-50"
              :class="{
                'active-folder-link': route.query?.spaceId === id,
              }"
            >
              <p
                :class="`folders-dropdown__name grow text-left text-black ${rail ? 'typo-body1' : 'typo-body2'}`"
              >
                {{ name }}
              </p>
            </div>
          </RouterLink>
          <div
            v-if="rail"
            class="flex align-center justify-space-between border-t border-primary-50 px-4 py-2 text-primary"
          >
            <span class="typo-body1">Create folder</span>
            <div
              class="flex align-center justify-center w-5 h-5 bg-primary-50 cursor-pointer rounded"
            >
              <Icon
                class="text-primary"
                size="12"
                :src="PlusIcon"
                @mouseover="toggleReadonlyState"
                @mouseout="toggleReadonlyState"
                @click="createFolder"
              />
            </div>
          </div>
        </template>
      </template>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<style>
.folders-dropdown.v-expansion-panel--active {
  @apply bg-primary-25 !important;
}
.folders-dropdown .v-expansion-panel-title {
  @apply p-0 min-h-0;
}

.folders-dropdown.v-expansion-panel--active .v-expansion-panel-title {
  @apply min-h-0;
}

.folders-dropdown.v-expansion-panel--active .folders-dropdown__title-icon {
  @apply text-white bg-primary;
}

.folders-dropdown .v-expansion-panel-title__overlay {
  @apply bg-transparent rounded;
}

.folders-dropdown .v-expansion-panel-text__wrapper {
  @apply flex flex-col p-0 bg-primary-25;
}

.folders-dropdown .folders-dropdown__dot-icon {
  @apply text-grey-20 !important;
}

.folders-dropdown .active-folder-link {
  @apply bg-primary bg-opacity-10 rounded;
}

.folders-dropdown .active-folder-link .folders-dropdown__dot-icon {
  @apply text-primary !important;
}

.folders-dropdown .active-folder-link .folders-dropdown__name {
  @apply text-primary !important;
}

.rail-panel .v-expansion-panel-text {
  @apply fixed w-[240px] rounded-b-lg bg-white shadow-soft;
}

.rail-panel .v-expansion-panel-text .folder-link {
  @apply pl-4 !important;
}
</style>
