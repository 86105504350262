<script setup lang="ts">
import { useManageFolder } from '@/components/settings/Folders/hooks';

const {
  isDeleteConfirmationOpen,
  closeDeleteConfirmation,
  shouldDeleteConversations,
  isDeleting,
  isActionInProgress,
  deleteFolder,
} = useManageFolder();
</script>
<template>
  <v-dialog
    v-model="isDeleteConfirmationOpen"
    persistent
    max-width="544"
    class="delete-folder-confirmation"
  >
    <div
      class="flex flex-col gap-y-4 lg:gap-y-8 bg-white w-full overflow-hidden rounded-2xl p-4 lg:p-8 border border-grey-20 border-solid relative"
    >
      <ClosePopupButton
        :on-click="closeDeleteConfirmation"
        :disabled="isActionInProgress"
      />
      <div class="flex flex-col gap-y-3">
        <h4 class="max-lg:typo-h5 lg:text-2xl lg:font-bold leading-normal">
          Delete folder
        </h4>
        <p class="typo-body1 font-normal">
          Would you like to delete the whole folder with all conversations, or
          delete the folder but save the conversations?
        </p>
      </div>
      <div
        class="flex max-sm:flex-col-reverse sm:items-center justify-end gap-3"
      >
        <v-btn
          variant="text"
          color="primary"
          height="36"
          :disabled="isActionInProgress"
          :loading="isDeleting && !shouldDeleteConversations"
          @click="deleteFolder(false)"
        >
          Save conversations
        </v-btn>
        <v-btn
          elevation="0"
          color="error-functional-500"
          height="36"
          :disabled="isActionInProgress"
          :loading="isDeleting && shouldDeleteConversations"
          @click="deleteFolder(true)"
        >
          Delete conversations
        </v-btn>
      </div>
    </div>
  </v-dialog>
</template>
<style>
.delete-folder-confirmation__close-btn {
  @apply absolute top-8 right-8 translate-x-1/2 -translate-y-1/2;
}

.delete-folder-confirmation__close-btn .v-btn__overlay {
  @apply bg-transparent;
}

.delete-folder-confirmation__close-btn .v-icon {
  @apply text-grey-90;
}
</style>
