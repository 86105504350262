<script setup lang="ts">
import { computed } from 'vue';
import { useUser } from '@/auth/use-session';

withDefaults(
  defineProps<{
    avatarSize?: number;
    hideName?: boolean;
    nameClass?: string;
  }>(),
  {
    nameClass: 'typo-body2',
  },
);

const { data: user } = useUser();
const name = computed(() => {
  const fb = 'user';
  return user.value ? user.value.name || user.value.email || fb : fb;
});
</script>
<template>
  <div v-if="user" class="flex items-center gap-x-2">
    <LetterAvatar
      :name="name"
      :image="user?.avatarUrl ?? ''"
      :image-props="{ cover: true }"
      :size="avatarSize"
    />
    <span v-if="!hideName" :class="`${nameClass} font-semibold`">{{
      name
    }}</span>
  </div>
</template>
