import { ref, Ref } from 'vue';
import { TranscriptHook, useTranscript } from '.';
import {
  useToggleHighlight as useToggleHighlightAPI,
  useUpdateTranscriptLines as useUpdateTranscriptLinesAPI,
  useDeleteTranscriptLine as useDeleteTranscriptLineAPI,
} from '@/api/transcript';
import { UpdateTranscriptLinesInput } from '@/api/types';
import { isStringOrError } from '@/utils';
import { showError } from '@/utils/alert';

interface ManageTranscriptHook extends TranscriptHook {
  isHighlightUpdating: Ref<boolean>;
  isEditing: Ref<boolean>;
  isSaving: Ref<boolean>;
  toggleEditing: () => void;
  toggleHighlight: (lineId: string) => Promise<void>;
  deleteLine: (lineId: string) => Promise<void>;
  resetChanges: () => void;
  saveChanges: () => Promise<void>;
}

const isHighlightUpdating = ref(false);
const isEditing = ref(false);
const isSaving = ref(false);

const toggleEditing = (): void => {
  isEditing.value = !isEditing.value;
};

const useToggleHighlight =
  (
    toggleHighlightMutation: ReturnType<
      typeof useToggleHighlightAPI
    >['mutateAsync'],
  ) =>
  async (lineId: string): Promise<void> => {
    try {
      isHighlightUpdating.value = true;
      await toggleHighlightMutation(lineId);
    } catch (error) {
      if (isStringOrError(error)) {
        showError(error);
      }
    } finally {
      isHighlightUpdating.value = false;
    }
  };

const useDeleteTranscriptLine =
  (
    deleteTranscriptLineMutation: ReturnType<
      typeof useDeleteTranscriptLineAPI
    >['mutateAsync'],
  ) =>
  async (lineId: string): Promise<void> => {
    try {
      await deleteTranscriptLineMutation(lineId);
    } catch (error) {
      if (isStringOrError(error)) {
        showError(error);
      }
    }
  };

const useSaveChanges =
  (
    transcript: TranscriptHook['transcript'],
    updateTranscriptLinesMutation: ReturnType<
      typeof useUpdateTranscriptLinesAPI
    >['mutateAsync'],
  ) =>
  async (): Promise<void> => {
    try {
      isEditing.value = false;
      isSaving.value = true;

      const updates = transcript.value.reduce(
        (acc, { id, text, originalText }) => [
          ...acc,
          ...(text !== originalText ? [{ id, text }] : []),
        ],
        [] as UpdateTranscriptLinesInput,
      );
      updates.length && (await updateTranscriptLinesMutation(updates));
    } catch (error) {
      if (isStringOrError(error)) {
        showError(error);
      }
    } finally {
      isSaving.value = false;
    }
  };

export const useManageTranscript = (): ManageTranscriptHook => {
  const transcriptHook = useTranscript();
  const resetChanges = (): void => {
    transcriptHook.transcript.value.forEach((item) => {
      item.text = item.originalText;
    });
    isEditing.value = false;
  };

  const { mutateAsync: toggleHighlightMutation } = useToggleHighlightAPI();
  const toggleHighlight = useToggleHighlight(toggleHighlightMutation);

  const { mutateAsync: deleteTranscriptLineMutation } =
    useDeleteTranscriptLineAPI();
  const deleteLine = useDeleteTranscriptLine(deleteTranscriptLineMutation);

  const { mutateAsync: updateTranscriptLinesMutation } =
    useUpdateTranscriptLinesAPI();
  const saveChanges = useSaveChanges(
    transcriptHook.transcript,
    updateTranscriptLinesMutation,
  );

  return {
    ...transcriptHook,
    isHighlightUpdating,
    isEditing,
    isSaving,
    toggleEditing,
    toggleHighlight,
    deleteLine,
    resetChanges,
    saveChanges,
  };
};
