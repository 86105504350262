<script setup lang="ts">
import { RouteLocationRaw } from 'vue-router';
import { useSidebarSize } from '@/components/SidebarMenu/hooks';
import type { TablerIconComponent } from 'vue-tabler-icons';

withDefaults(
  defineProps<{
    title?: string;
    to?: RouteLocationRaw;
    icon?: TablerIconComponent | string;
    iconSize?: number | string;
    railIconSize?: number | string;
  }>(),
  {
    iconSize: 16,
    railIconSize: 20,
  },
);

const { rail } = useSidebarSize();
</script>

<template>
  <RouterLink
    :key="title"
    v-slot="{ isActive }"
    :class="[
      'sidebar-link flex gap-x-2 align-center h-12 p-2 cursor-pointer rounded hover:bg-primary-50',
      { 'justify-center': rail },
    ]"
    :to="to"
  >
    <div
      class="sidebar-link__icon flex align-center justify-center w-6 h-6 rounded"
    >
      <Icon
        :src="icon"
        :size="rail ? (isActive ? iconSize : railIconSize) : iconSize"
      />
    </div>
    <span v-if="!rail" class="typo-body1 text-black font-bold">{{
      title
    }}</span>
  </RouterLink>
</template>

<style lang="scss">
.sidebar-link.router-link-active {
  @apply bg-primary-25;

  .sidebar-link__icon {
    @apply bg-primary text-white;
  }
}
</style>
