import { OnboardingStepEnum } from '@/utils/onboarding';
import { WorkspaceConversationEndEmailTarget } from '@/api/types';
import { IMAGES } from '@/constants/assets';

type OnboardingOptions = {
  id: string;
  label: string;
  description?: string;
  items?: string[];
  icons?: string[];
};

export const workFields: string[] = [
  'Sales',
  'Design',
  'Product',
  'Project Management',
  'Marketing',
  'Engineering',
  'Consulting',
  'Customer Success',
  'Operation',
  'Finance',
  'Executive',
];

export const usageOptions: OnboardingOptions[] = [
  {
    id: 'work',
    label: 'For work',
    description: 'What type of work do you do?',
    items: workFields,
  },
  {
    id: 'education',
    label: 'For education',
    description:
      'Noty.ai provides exclusive discounts for non-profit organizations and educational users',
  },
  {
    id: 'personal',
    label: 'For personal use',
    description:
      'Noty.ai is the ultimate AI companion, empowering you to achieve your goals effortlessly',
  },
];
export const goalsOptions: OnboardingOptions[] = [
  {
    id: 'perform',
    label: 'Become a top performer',
    description:
      'Noty helps you increase your productivity by 128% by taking care of post-meeting admin tasks.',
    icons: [IMAGES.MEDAL, IMAGES.FIRE],
  },
  {
    id: 'calm',
    label: 'Achieve peace of mind',
    description:
      'Free up more headspace with a reliable personal AI meeting assistant to do your meeting admin.',
    icons: [IMAGES.MEDITATION, IMAGES.CUP],
  },
  {
    id: 'rushless',
    label: 'Feel less rushed & have more time',
    description:
      'Save up to 20 hours a month with AI-generated meeting transcriptions, summaries, and to-do lists.',
    icons: [IMAGES.CLOCK, IMAGES.GLASSES],
  },
  {
    id: 'confident',
    label: 'Feel more confident in meetings',
    description:
      'Stay on top of the conversation as it happens with real-time transcriptions that are 95% accurate.',
    icons: [IMAGES.BAG, IMAGES.RUNNER],
  },
  {
    id: 'control',
    label: 'Feel in control of my workflow',
    description:
      'Use Noty to quickly organise meeting follow-ups, outcomes, and to-do lists 3x faster.',
    icons: [IMAGES.LAPTOP, IMAGES.CHECK],
  },
];
export const extensionOptions: OnboardingOptions[] = [
  {
    id: '1',
    label: 'Go to the',
    description: 'in the Chrome store.',
  },
  {
    id: '2',
    label: 'Click “Add to Chrome” to start the installation.',
  },
  {
    id: '3',
    label: 'Follow the steps to install the extension.',
  },
];
export const rewindOptions: OnboardingOptions[] = [
  {
    id: WorkspaceConversationEndEmailTarget.WORKSPACE_DOMAINS,
    label: 'Only me and participants from my team',
  },
  {
    id: WorkspaceConversationEndEmailTarget.EVERYONE,
    label: 'Everyone on the invite',
  },
  {
    id: WorkspaceConversationEndEmailTarget.NOBODY,
    label: 'Nobody',
  },
];

export const onboardingMap: {
  [key in OnboardingStepEnum]: {
    title: string;
    secondTitle?: string;
    description?: string;
    options?: OnboardingOptions[];
  };
} = {
  [OnboardingStepEnum.USAGE]: {
    title: 'How do you plan to use Noty?',
    options: usageOptions,
  },
  [OnboardingStepEnum.GOAL]: {
    title: "What's the goal you want to reach?",
    description: 'Choose as many or as few options as you like',
    options: goalsOptions,
  },
  [OnboardingStepEnum.WORKSPACE]: {
    title: 'Create your workspace',
    secondTitle: 'Join the workspace!',
    description:
      'Meeting information, tasks and insights are organised in workspaces and folders. You can create one workspace with many folders or a few workspaces for each project.',
    options: goalsOptions,
  },
  [OnboardingStepEnum.CARD]: {
    title: '',
  },
  [OnboardingStepEnum.EXTENSION]: {
    title: 'Installing the Chrome extension',
    options: extensionOptions,
  },
  [OnboardingStepEnum.DONE]: {
    title: 'Welcome to Noty.ai!',
  },
  [OnboardingStepEnum.SETTINGS]: {
    title: 'Get meeting notes right',
    options: rewindOptions,
  },
  [OnboardingStepEnum.INVITE]: {
    title: 'Invite your team members',
    description: 'Invite to build a team of A-players',
    options: rewindOptions,
  },
};
