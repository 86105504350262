<script setup lang="ts">
import DoneStep from '@/components/onboarding/DoneStep.vue';
import { ref, watch } from 'vue';
import { useUser } from '@/auth/use-session';
import { useUpdateUserData } from '@/api/users';
import { openExtensionPage } from '@/extension/helpers';
import { onboardingMap } from '@/components/onboarding/onboarding.config';
import { EXTENSION_INSTALLED } from '@/extension/listeners';
import { OnboardingStatus } from '@/api/types';
import { OnboardingStepEnum } from '@/utils/onboarding';

const isActive = ref(false);
const isOnboardingExtension = ref(false);
const showDoneStep = ref(false);

const { data: user } = useUser();
const { mutateAsync: setOnboardingStatus, isLoading } = useUpdateUserData();

const installExtensionHandler = () => {
  if (EXTENSION_INSTALLED) {
    return;
  }
  openExtensionPage();
  changesOnboardingStatus();
  isActive.value = false;
};

const skipClickHandler = () => {
  changesOnboardingStatus();
  isActive.value = false;
};

const changesOnboardingStatus = () => {
  if (isOnboardingExtension.value) {
    setOnboardingStatus({ onboardingStatus: OnboardingStatus.Done });
  }
};

const closeDoneStep = () => {
  if (EXTENSION_INSTALLED) {
    skipClickHandler();
  }
  showDoneStep.value = false;
};

watch(
  user,
  (user) => {
    if (!user) {
      return;
    }

    isOnboardingExtension.value =
      user.onboardingStatus === OnboardingStatus.Extension;

    if (isOnboardingExtension.value) {
      localStorage.setItem('showSettingsPopup', 'true');
    }
    if (!isOnboardingExtension.value && EXTENSION_INSTALLED) {
      return;
    }

    isActive.value =
      user.onboardingStatus !== OnboardingStatus.Done
        ? isOnboardingExtension.value
        : !localStorage.getItem('showSettingsPopup');

    if (isOnboardingExtension.value) {
      showDoneStep.value = true;
      setTimeout(() => {
        closeDoneStep();
      }, 2000);
    }
  },
  { immediate: true },
);
</script>

<template>
  <DoneStep v-if="showDoneStep" />
  <v-dialog v-else :model-value="isActive" persistent>
    <div
      class="ms:w-[600px] m-auto flex flex-col gap-y-8 px-4 py-6 sm:p-[40px] rounded-[28px] bg-white overflow-auto"
    >
      <h2
        class="max-sm:text-center text-black font-geologica text-[24px] sm:text-[36px] leading-tight sm:leading-[48px] font-bold"
      >
        {{ onboardingMap[OnboardingStepEnum.EXTENSION].title }}
      </h2>
      <div class="flex flex-col gap-y-5">
        <div
          v-for="option in onboardingMap[OnboardingStepEnum.EXTENSION].options"
          :key="option.id"
          class="flex items-center gap-x-4 typo-body1"
        >
          <span
            class="flex items-center justify-center shrink-0 bg-primary rounded-full w-8 h-8 font-semibold"
          >
            {{ option.id }}
          </span>
          <div v-if="option.id === '1'">
            <span class="text-black">{{ option.label }}</span>
            <span
              class="text-primary cursor-pointer"
              @click="openExtensionPage"
            >
              Noty.ai extension
            </span>
            <span class="text-black">{{ option.description }}</span>
          </div>
          <div v-else class="text-black">
            {{ option.label }}
          </div>
        </div>
      </div>
      <div
        class="grid grid-cols-2 md:flex md:justify-end md:items-center gap-x-3 md:gap-x-8"
      >
        <v-btn
          variant="text"
          text="Skip"
          class="bg-white text-grey-100 px-4 py-2"
          :loading="isLoading"
          @click="skipClickHandler"
        />
        <v-btn
          text="Install Extension"
          class="px-2.5 py-2"
          color="primary"
          :disabled="isLoading"
          @click="installExtensionHandler"
        />
      </div>
    </div>
  </v-dialog>
</template>
