<script setup lang="ts">
import { computed } from 'vue';
import { Pricing, SubscriptionInterval } from '@/api/types';
import {
  useUpdatePlan,
  PlanDifference,
  BillingPeriodsMap,
} from '@/components/settings/Billing/hooks';
import { PlansPeriodSelector } from '@/components/settings/Billing';
import { FOLDER_MEMBER_ROLES_OPTIONS } from '@/constants/dropdownOptions';
import { MONTHS_IN_PERIOD } from '@/constants/common';
import { useDisplay } from 'vuetify';

const {
  selectedPlan,
  billingPeriodSelector,
  planDifference,
  clearPlan,
  popupTitle,
  workspaceUsers,
  monthlyTotal,
  periodTotal,
  update,
  isUpdating,
} = useUpdatePlan();
const { lgAndUp } = useDisplay();

const isPersonal = computed(
  () => selectedPlan.value?.pricing === Pricing.PERSONAL,
);
</script>
<template>
  <v-dialog
    :model-value="!!selectedPlan"
    persistent
    max-width="568"
    class="update-plan-popup"
  >
    <div
      class="flex flex-col gap-y-4 bg-white w-full max-h-[525px] overflow-hidden rounded-2xl p-4 lg:p-6 border border-grey-20 border-solid"
    >
      <h4 class="max-lg:typo-h5 lg:text-2xl lg:font-bold leading-normal">
        {{ popupTitle }}
      </h4>
      <p class="typo-body1">Please review user and billing details.</p>
      <div class="flex flex-col gap-y-4 min-h-[200px]">
        <PlansPeriodSelector
          class="update-plan-popup__plans-selector grid grid-cols-3 text-center w-full"
        />
        <div class="flex flex-col gap-y-3 max-h-[210px] overflow-y-auto">
          <div
            v-for="(
              { accountId, name, email, avatarUrl, isCurrentUser, userPrice },
              index
            ) in workspaceUsers"
            :key="accountId"
            class="flex justify-between gap-x-2 lg:gap-x-4"
          >
            <div class="flex align-center gap-x-2">
              <LetterAvatar
                :name="name || email || 'Unknown'"
                :image="avatarUrl"
                :size="lgAndUp ? 36 : 24"
              />
              <div :class="['flex flex-col', { 'justify-between': name }]">
                <div class="flex items-center gap-x-1">
                  <span v-if="name" class="typo-body1 text-ellipsis-line-clamp">
                    {{ name }}
                  </span>
                  <div
                    v-if="isCurrentUser"
                    class="shrink-0 px-1 py-[2px] font-inter text-2xs leading-3 font-medium text-primary bg-primary-50 rounded-[14px]"
                  >
                    That's you
                  </div>
                </div>
                <span class="typo-body2 text-ellipsis-line-clamp">
                  {{ email }}
                </span>
              </div>
            </div>
            <div
              class="flex max-lg:flex-col-reverse items-center shrink-0 gap-x-4"
            >
              <span class="typo-body2 font-medium max-lg:align-self-end">
                ${{ userPrice }}
              </span>
              <v-select
                v-model="workspaceUsers[index].role"
                variant="plain"
                density="compact"
                :items="FOLDER_MEMBER_ROLES_OPTIONS"
                item-title="name"
                class="update-plan-popup__role-select align-end"
                disabled
                hide-details
              >
                <template #item="{ props }">
                  <v-list-item
                    v-bind="props"
                    density="compact"
                    class="update-plan-popup__role-select__item"
                  />
                </template>
              </v-select>
            </div>
          </div>
        </div>
        <div
          v-if="planDifference !== PlanDifference.Equal && !isPersonal"
          class="flex flex-col max-lg:p-2 max-lg:pb-0 max-lg:border-t border-grey-10"
        >
          <div class="flex items-center justify-between">
            <span class="typo-body1 font-semibold">Total</span>
            <div class="flex items-center gap-x-1">
              <span
                v-if="billingPeriodSelector !== SubscriptionInterval.Month"
                class="typo-body1"
              >
                {{ MONTHS_IN_PERIOD[billingPeriodSelector] }} months x
              </span>
              <span class="font-inter text-xl font-semibold">
                ${{ monthlyTotal }} <span class="max-sm:hidden">per month</span>
              </span>
            </div>
          </div>
          <span
            v-if="billingPeriodSelector !== SubscriptionInterval.Month"
            class="typo-body1 font-medium self-end"
          >
            ${{ `${periodTotal} ${BillingPeriodsMap[billingPeriodSelector]}` }}
          </span>
        </div>
      </div>
      <div
        class="grid grid-cols-2 lg:flex lg:justify-end lg:items-center gap-x-2 lg:gap-x-4"
      >
        <v-btn
          color="primary"
          variant="text"
          height="36"
          class="px-4 typo-body2 sm:typo-body1 font-semibold"
          text="Cancel"
          :disabled="isUpdating"
          @click="clearPlan"
        />
        <v-btn
          height="36"
          elevation="0"
          class="px-[10px] typo-body2 sm:typo-body1 font-semibold"
          :color="isPersonal ? undefined : 'primary'"
          :text="isPersonal ? 'Downgrade' : ' Proceed to checkout'"
          :disabled="planDifference === PlanDifference.Equal || isUpdating"
          :loading="isUpdating"
          @click="update"
        />
      </div>
    </div>
  </v-dialog>
</template>
<style>
.update-plan-popup__role-select .v-field__input {
  @apply p-0 typo-body2 min-h-0 h-auto items-center;
}

.update-plan-popup__role-select .v-field.v-field--disabled {
  @apply opacity-100;
}

.update-plan-popup__role-select
  .v-field.v-field--disabled
  .v-field__append-inner {
  @apply hidden;
}

.update-plan-popup__role-select.v-select .v-field__input .v-select__selection {
  @apply m-0 ms-0 me-0 min-w-[45px] justify-end lg:justify-start;
}

.update-plan-popup__role-select
  .v-field.v-field--variant-plain
  .v-field__append-inner {
  @apply items-center py-0;
}

.update-plan-popup__role-select
  .v-field.v-field--variant-plain
  .v-field__append-inner
  .v-icon {
  @apply min-h-0 h-auto min-w-0 w-auto;
}

.update-plan-popup__role-select__item {
  @apply py-1 ps-2 pe-2 min-h-0 !important;
}

.update-plan-popup__role-select__item .v-list-item-title {
  @apply typo-body1;
}

.update-plan-popup .update-plan-popup__plans-selector {
  @apply lg:w-full !important;
}
</style>
