<script setup lang="ts">
import { AvatarProps, Identifiable } from '@/api/types';
import LetterAvatar from '@/components/common/molecules/LetterAvatar.vue';
import { computed } from 'vue';

const props = defineProps<{
  name: string;
  conversationsQuantity: number;
  accessors: Array<AvatarProps & Identifiable>;
}>();

const VISIBLE = 4;
const visibleAccessors = computed(() => props.accessors?.slice(0, VISIBLE));
const extra = computed(() => props.accessors?.length - VISIBLE);
</script>
<template>
  <div
    class="flex max-lg:flex-col items-start justify-between lg:items-center gap-y-2 gap-x-4 p-4 border border-solid border-grey-20 rounded"
  >
    <span class="typo-body1 font-normal max-lg:font-medium">{{ name }}</span>
    <div class="flex max-lg:justify-between max-lg:w-full gap-x-1">
      <span class="typo-body2">{{ conversationsQuantity }} conversations</span>
      <div class="flex items-center gap-x-1">
        <div class="flex items-center">
          <LetterAvatar
            v-for="accessor in visibleAccessors"
            :key="accessor.id"
            v-tooltip="accessor.name || accessor.email || ''"
            :name="accessor.name || accessor.email || ''"
            :image="accessor.avatarUrl ?? ''"
            :size="18"
            class="-ml-[8px] first:ml-0 outline outline-1 outline-white"
            letter-class="font-noto text-[11px] leading-[18px]"
          />
        </div>
        <span v-if="extra > 0" class="typo-body2"> +{{ extra }} </span>
      </div>
    </div>
  </div>
</template>
